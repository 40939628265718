import React from "react"
import PropTypes from "prop-types"
import { Provider as ReduxProvider } from "react-redux"
import { createStore } from "redux"
import rootReducer from "."

const store = () => createStore(rootReducer)

const Provider = ({ element }) => (
  <ReduxProvider store={store()}>{element}</ReduxProvider>
)

export default Provider

Provider.propTypes = {
  element: PropTypes.object,
}
