const initialState = {
  pages: [],
  content: [],
  heading: [],
  teasers: [],
  splitImageText: [],
}

// ACTION TYPES

const INIT_PAGES = "INIT_PAGES"
const INIT_CONTENT = "INIT_CONTENT"
const INIT_HEADING = "INIT_HEADING"
const INIT_SPLITIMAGETEXT = "INIT_SPLITIMAGETEXT"
const INIT_TEASERS = "INIT_TEASERS"

// ACTIONS

export const initPages = (pages) => ({ type: INIT_PAGES, pages })

export const initContent = (content) => ({ type: INIT_CONTENT, content })

export const initHeading = (heading) => ({ type: INIT_HEADING, heading })

export const initSplitImageText = (splitImageText) => ({ type: INIT_SPLITIMAGETEXT, splitImageText })

export const initTeasers = (teasers) => ({ type: INIT_TEASERS, teasers })

// REDUCER

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_PAGES:
      return { ...state, pages: action.pages }
    case INIT_CONTENT:
      return { ...state, content: action.content }
    case INIT_HEADING:
      return { ...state, heading: action.heading }
    case INIT_SPLITIMAGETEXT:
      return { ...state, splitImageText: action.splitImageText }
    case INIT_TEASERS:
      return { ...state, teasers: action.teasers }
    default:
      return state
  }
}

export default Reducer
